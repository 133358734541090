import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../../layout";
import Book from "../../components/book/book";
import _ from "lodash";

class BookshelfPage extends Component {
  render() {
    let books = this.props.data.books.frontmatter.content;
    let coverMap = {};
    this.props.data.covers.edges.forEach(edge => {
      coverMap[edge.node.name] = edge.node.childImageSharp.gatsbyImageData;
    });

    books.forEach(book => {
      const title = _.kebabCase(book.title);
      if (coverMap[title]) {
        book.cover = coverMap[title];
      } else {
        book.cover = null;
      }
    });

    const booksByYear = Object.entries(_.groupBy(books, b => b.year))
      .sort()
      .reverse();

    return (
      <Layout title="bookshelf">
        <h1>bookshelf</h1>
        <p>
          do you ever read something and feel nostalgic about a place you have
          never been to?
        </p>
        <p>send me your book recommendations!</p>

        <section className="mb-6">
          {booksByYear.map(entry => {
            const year = entry[0];
            const hoverable = year != "to read";
            const books = entry[1].reverse();
            return (
              <div>
                <h2 className="mt-6">{year}</h2>
                <div className="columns is-multiline">
                  {books.map(b => {
                    return (
                      <div
                        className={
                          "column is-1 overlap-book" +
                          (hoverable ? " hoverable" : "")
                        }
                      >
                        <Book data={b} />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query BookshelfPageQuery {
    books: markdownRemark(frontmatter: { for: { eq: "bookshelf" } }) {
      frontmatter {
        content {
          title
          author
          year
          emote
          rating
          review
        }
      }
    }
    covers: allFile(filter: { relativeDirectory: { eq: "img/books" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, height: 180)
          }
        }
      }
    }
  }
`;

export default BookshelfPage;
